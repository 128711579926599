// extracted by mini-css-extract-plugin
export var awsExpertImg = "SustainabilityMain-module--awsExpertImg--h1Bl-";
export var awsExpertImgDiv = "SustainabilityMain-module--awsExpertImgDiv--g8oqg";
export var awsMainImage = "SustainabilityMain-module--awsMainImage--YYneo";
export var businessImage = "SustainabilityMain-module--businessImage---uz8U";
export var businessItem = "SustainabilityMain-module--businessItem--7laEL";
export var businessItemImage = "SustainabilityMain-module--businessItemImage--UIKa+";
export var businessItemMain = "SustainabilityMain-module--businessItemMain--38HPr";
export var businessMain = "SustainabilityMain-module--businessMain--bnlaA";
export var businessText = "SustainabilityMain-module--businessText--c7EEe";
export var buttonClasses = "SustainabilityMain-module--buttonClasses--jsnYN";
export var carouselContainer = "SustainabilityMain-module--carouselContainer--ZXqFH";
export var caseChallengeImage = "SustainabilityMain-module--caseChallengeImage--yc7Fx";
export var caseTestimonialImage = "SustainabilityMain-module--caseTestimonialImage--JTxkN";
export var contactButton = "SustainabilityMain-module--contactButton--orJAL";
export var heroBackgroundCircleLeft = "SustainabilityMain-module--heroBackgroundCircleLeft--1mrcD";
export var heroBackgroundInnerCircleLeft = "SustainabilityMain-module--heroBackgroundInnerCircleLeft--3LDvq";
export var heroWhiteBlurCirce = "SustainabilityMain-module--heroWhiteBlurCirce--x5ptn";
export var knowUs = "SustainabilityMain-module--knowUs--R88wP";
export var knowUsImage = "SustainabilityMain-module--knowUsImage--Ttqcq";
export var knowUsSubDiv = "SustainabilityMain-module--knowUsSubDiv--4OZRd";
export var knowUsText = "SustainabilityMain-module--knowUsText--gSJec";
export var partners = "SustainabilityMain-module--partners--ChGMB";
export var partnersBenefits = "SustainabilityMain-module--partnersBenefits--QfzuE";
export var partnersBenefitsCarousel = "SustainabilityMain-module--partnersBenefitsCarousel--g4g+f";
export var partnersBenefitsInner = "SustainabilityMain-module--partnersBenefitsInner--Zym-5";
export var partnersBenefitsItem = "SustainabilityMain-module--partnersBenefitsItem--WYUXr";
export var partnersBenefitsItemBusiness = "SustainabilityMain-module--partnersBenefitsItemBusiness--HbcCo";
export var partnersBenefitsItemBusinessTop = "SustainabilityMain-module--partnersBenefitsItemBusinessTop--87Qjx";
export var partnersBenefitsTech = "SustainabilityMain-module--partnersBenefitsTech--PipAH";
export var partnersButtonsContainer = "SustainabilityMain-module--partnersButtonsContainer--+kzsQ";
export var partnersMainSlider = "SustainabilityMain-module--partnersMainSlider--2hfX3";
export var singleBenefitTextBox = "SustainabilityMain-module--singleBenefitTextBox--frkeZ";
export var singleCaseChallenge = "SustainabilityMain-module--singleCaseChallenge--JcFE8";
export var singleCaseContentBlock = "SustainabilityMain-module--singleCaseContentBlock--uAvRc";
export var singleCaseImageBox1 = "SustainabilityMain-module--singleCaseImageBox1--0fS7p";
export var singleCaseSolution = "SustainabilityMain-module--singleCaseSolution--PGDRu";
export var singleCaseTestimonial = "SustainabilityMain-module--singleCaseTestimonial--I2o2J";
export var singleCaseTextBox = "SustainabilityMain-module--singleCaseTextBox--YsRIM";
export var testimonialContent = "SustainabilityMain-module--testimonialContent--N9paO";