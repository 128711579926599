import React, { FC } from "react";
import * as styles from "./SustainabilityMain.module.scss";
import tableDesktop from "../../images/sustainability.jpg";
import tableDesktopMobile from "../../images/mobile-images/sustainability.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import { sustainability } from "./SustainabilityMain.helper";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../i18n/translate";
import SectionWrapper from "../Shared/SectionWrapper/SectionWrapper";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
import { EdenReforestationData } from "./SustainabilityMain.helper";
import GreenitImg from "../../images/greenit.jpg";

const SustainabilityMain: FC = () => {
  const width = UseWindowSize();

  return (
    <div className={styles.partners}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("sustainability-heading")}
          description={translate("sustainability-heading-description")}
          homeBackground={tableDesktopMobile}
          buttonText={translate("sustainability-action-button")}
          date=""
          link="https://www.edenprojects.org/"
          alt="Explore the Edenproject"
        />
      ) : (
        <HeroBanner
          heading={translate("sustainability-heading")}
          description={translate("sustainability-heading-description")}
          homeBackground={tableDesktop}
          buttonText={translate("sustainability-action-button")}
          date=""
          link="https://www.edenprojects.org/"
        />
      )}

      <div className={styles.knowUs}>
        <div className={styles.knowUsImage}>
          <div className={styles.knowUsSubDiv}>
            <iframe
              width="100%"
              height="450px"
              src="https://www.youtube-nocookie.com/embed/XKynjGwBawE"
              title="Eden Project"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
        <div className={styles.knowUsText}>
          <h2 className="heading">
            <span>.</span>
            {sustainability?.title}
          </h2>
          <p>{translate("sustainability-description")}</p>
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
        </div>
      </div>
      <InnerWrapper>
        <div>
          <SectionWrapper>
            <>
              <div className={styles.singleCaseContentBlock}>
                <div className={styles.singleCaseSolution}>
                  <div className={styles.singleCaseTextBox}>
                    <div>
                      <h2 style={{ marginBottom: "0px" }}>
                        .{translate("sustainability-green-it-header")}
                      </h2>
                      <p style={{ marginRight: 50 }}>
                        {translate("sustainability-green-it-description")}
                      </p>
                    </div>
                    <CircleBlur
                      initialScale={0.1}
                      animateScale={1}
                      initialOpacity={0}
                      animateOpacity={0.85}
                      transitionType={"easeIn"}
                      transitionDelay={0.2}
                      transitionDuration={1}
                      circleStyleClass={styles.heroWhiteBlurCirce}
                    />
                  </div>
                  <div className={styles.singleCaseImageBox1}>
                    <img
                      src={GreenitImg}
                      alt="Green IT Forest by globaldatanet"
                    />
                  </div>
                </div>
              </div>
              <TwoColumnBanner data={EdenReforestationData} ourStory />
            </>
          </SectionWrapper>
        </div>
      </InnerWrapper>
    </div>
  );
};

export default SustainabilityMain;
