import translate from "../../i18n/translate";
import EdenImg from "../../images/eden.jpg";

export const MainData = {
  heading1: translate("sustainability-eden-header"),
  paragraph1: translate("sustainability-eden-description"),
};

export const EdenReforestationData = {
  heading: MainData.heading1,
  details: MainData.paragraph1,
  img: EdenImg,
  alt: "Seeds from the Eden Reforestation by globaldatanet",
};

export const sustainability = {
  title: translate("sustainability-description-header"),
  description: translate("sustainability-description"),
};
