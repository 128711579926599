import React from "react";
import SustainabilityMain from "../components/SustainabilityUiComponents/SustainabilityMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import tableDesktop from "../images/sustainability.jpg";
const Sustainability = () => {
  return (
    <Layout location="/sustainability">
      <SEO
        title="Sustainability"
        description="Sustainability is not just a word for us at globaldatanet - we live it. We love to develop sustainable, efficient and Serverless architectures that use energy and resources only when they are really needed."
        type="website"
        image={tableDesktop}
      />
      <SustainabilityMain />
    </Layout>
  );
};

export default Sustainability;
